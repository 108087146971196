<template>
	<v-card>
		<image-cropper
			v-if="cropShow"
			:crop-image-data.sync="cropImage"
			:show-dialog.sync="cropShow"
			@crop-apply="cropApply"
		/>
		<v-card-title>
			Anasayfa Banner Görseller
			<v-spacer></v-spacer>
			<v-tooltip top>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						depressed
						class="text-capitalize"
						@click="newBanner"
						:disabled="newBannerButtonIsDisabled"
						v-bind="attrs"
						v-on="on"
					>
						<v-icon left x-small>fas fa-plus</v-icon>
						yeni banner ekle
					</v-btn>
				</template>
				<span>Maksimum 5 adet banner ekleyebilirsiniz.</span>
			</v-tooltip>
		</v-card-title>
		<v-card-text>
			<draggable v-model="banners" draggable=".draggable-item" @change="onOrderChange">
				<v-list-item class="px-0 draggable-item" dense v-for="(banner, index) in banners" :key="`banner-${index}`">
					<v-list-item-content class="d-flex flex-row">
						<v-row align="center">
							<v-col cols="12" md="2" class="d-flex justify-center align-center">
								<v-icon left medium color="black">fa-ellipsis-vertical</v-icon>
								<v-img
									v-if="banner.cbnImage"
									contain
									width="80"
									height="50"
									:lazy-src="bannerImage(banner)"
									:src="bannerImage(banner)"
								></v-img>
								<v-img
									v-else-if="banner.bannerBlob"
									contain
									width="80"
									height="50"
									:lazy-src="banner.bannerBlob"
									:src="banner.bannerBlob"
								></v-img>

								<div v-else class="d-flex flex-column">
									<v-btn
										class="text-capitalize"
										color="grey lighten-3"
										depressed
										light
										@click="chooseBanner(index)"
										small
									>
										resim seç
									</v-btn>
									<div
										class="error--text font-weight-bold py-1"
										v-if="$v.cmsTheme.banners.$each[index].bannerBlob.$invalid"
									>
										Lütfen resim seçin
									</div>
									<input
										type="file"
										:ref="`bannerFile-${index}`"
										class="d-none"
										accept=".pdf, .png, .jpg, .jpeg, .gif, .bmp"
										@change="changeBannerFile($event, index)"
									/>
								</div>
							</v-col>
							<v-col cols="12" md="3">
								<v-text-field
									label="Başlık"
									outlined
									hide-details="auto"
									flat
									dense
									color="teal"
									clearable
									v-model="banner.cbnTitle"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="3">
								<v-text-field
									label="Alt Başlık"
									outlined
									hide-details="auto"
									flat
									dense
									color="teal"
									clearable
									v-model="banner.cbnSubTitle"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="3">
								<v-text-field
									label="Link"
									outlined
									hide-details="auto"
									flat
									dense
									color="teal"
									clearable
									v-model="banner.cbnLink"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="1">
								<v-text-field dense v-model="banner.cbnTextColor" hide-details outlined readonly>
									<template v-slot:append>
										<v-menu
											v-model="banner.colorMenu"
											top
											nudge-bottom="105"
											nudge-left="16"
											:close-on-content-click="false"
										>
											<template v-slot:activator="{ on }">
												<div class="elevation-1" :style="swatchStyle(banner)" v-on="on" />
											</template>
											<v-card>
												<v-card-text class="pa-0">
													<v-color-picker flat v-model="banner.cbnTextColor" />
												</v-card-text>
											</v-card>
										</v-menu>
									</template>
								</v-text-field>
							</v-col>
						</v-row>
					</v-list-item-content>
					<v-list-item-action>
						<v-btn outlined color="red darken-4" depressed class="text-capitalize" @click="removeBanner(banner)">
							<v-icon left x-small>fas fa-trash</v-icon>
							sil
						</v-btn>
					</v-list-item-action>
				</v-list-item>
			</draggable>
		</v-card-text>
	</v-card>
</template>

<script>
import draggable from 'vuedraggable';
import { validationMixin } from 'vuelidate';
import validations from './_validations';
import ImageCropper from './crop.vue';

export default {
	name: 'BannerList',
	components: {
		ImageCropper,
		draggable,
	},
	mixins: [validationMixin, validations],
	props: {
		cmsTheme: {
			type: Object,
			required: true,
		},
		checkFileExtFn: {
			type: Function,
			required: true,
		},
		getFileFn: {
			type: Function,
			required: true,
		},
		readAsDataFn: {
			type: Function,
			required: true,
		},
	},
	data() {
		return {
			cropImage: null,
			cropShow: false,
			croppingIamge: null,
		};
	},
	computed: {
		banners: {
			get() {
				return this.cmsTheme.banners.filter((x) => !x.isDeleted);
			},
			set(newBanners) {
				this.cmsTheme.banners = newBanners.filter((x) => !x.isDeleted);
			},
		},
		newBannerButtonIsDisabled() {
			return this.cmsTheme.banners.filter((x) => !x.isDeleted).length >= 5;
		},
	},
	methods: {
		onOrderChange() {
			const updatedBanners = this.cmsTheme.banners.map((banner, index) => ({
				...banner,
				cbnOrder: index + 1,
			}));

			this.cmsTheme.banners = updatedBanners;
		},
		newBanner() {
			const maxOrder =
				this.cmsTheme.banners && this.cmsTheme.banners.length > 0
					? Math.max(...this.cmsTheme.banners.map((obj) => obj.cbnOrder + 1))
					: 1;
			this.cmsTheme.banners.push({
				bannerBlob: null,
				fileName: null,
				cbnCmpId: this.cmsTheme.companyId,
				cbnOrder: maxOrder,
				cbnSubTitle: null,
				cbnTextColor: '#000000',
				cbnTitle: null,
				colorMenu: false,
				isDeleted: false,
				tmpUploaded: false,
			});
		},
		bannerImage(banner) {
			if (banner.tmpUploaded) {
				return `${banner.file}`;
			}
			return `${banner.filePath}`;
		},
		removeBanner(banner) {
			const { banners } = this.cmsTheme;

			const item = banners.find((x) => !x.isDeleted && x === banner);

			if (item.filePath && !item.tmpUploaded) item.isDeleted = true;
			else {
				const index = this.cmsTheme.banners.indexOf(item);
				this.cmsTheme.banners.splice(index, 1);
			}
		},
		chooseBanner(index) {
			const input = this.$refs[`bannerFile-${index}`][0];
			if (input) {
				input.click();
			}
		},
		async changeBannerFile(e, index) {
			const data = e.target.files[0];
			const allowedExtensions = /(\.png|\.jpg|\.jpeg)$/i;
			this.checkFileExtFn([data], allowedExtensions).then(async () => {
				if (data) {
					const banner = this.cmsTheme.banners.filter((x) => !x.isDeleted)[index];
					if (banner) {
						banner.fileName = data.name;
						banner.bannerBlob = null;
						banner.bannerBlobTemp = await this.readAsDataFn(data);
						this.cropImageStart(banner);
					}
				}
			});
		},
		cropImageStart(banner) {
			this.croppingIamge = banner;
			this.cropImage = banner.bannerBlobTemp;
			this.cropShow = true;
		},
		async cropApply(data) {
			const dataIndex = this.cmsTheme.banners.indexOf(this.croppingIamge);
			if (dataIndex > -1) {
				// const response = base64ToArrayBuffer(data);
				this.croppingIamge.bannerBlob = data;
				this.cmsTheme.banners.splice(dataIndex, 1, this.croppingIamge);
			}
		},
		swatchStyle(banner) {
			return {
				backgroundColor: banner.cbnTextColor,
				cursor: 'pointer',
				height: '25px',
				width: '25px',
				borderRadius: banner.colorMenu ? '50%' : '4px',
				transition: 'border-radius 200ms ease-in-out',
			};
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep {
	.draggable-item {
		cursor: move !important;
	}
}
</style>
