<template>
	<v-dialog scrollable fullscreen hide-overlay transition="dialog-bottom-transition" v-model="showDialog">
		<v-card flat tile>
			<v-card-title class="primary white--text">
				<v-btn icon dark @click="reset">
					<v-icon>fa-times</v-icon>
				</v-btn>
				Resim Düzenle
			</v-card-title>
			<v-divider></v-divider>
			<v-card-text class="d-flex justify-center align-center">
				<div style="width: 50%; height: 50%">
					<cropper
						class="cropper"
						:src="img"
						:image-restriction="restrictionType"
						:stencil-props="{
							movable: true,
							resizable: true,
							aspectRatio: 4 / 1.2,
						}"
						@change="change"
					/>
				</div>
			</v-card-text>
			<v-divider></v-divider>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="apply">Uygula</v-btn>
				<v-btn color="primary" @click="reset">Vazgeç</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
	name: 'ImageCropper',
	components: {
		Cropper,
	},
	props: {
		showDialog: {
			type: Boolean,
			default: () => false,
		},
		cropImageData: {
			default: null,
		},
	},
	data() {
		return {
			restrictionType: 'none',
			canvas: null,
		};
	},
	computed: {
		img() {
			return this.cropImageData;
		},
	},
	methods: {
		// eslint-disable-next-line no-unused-vars
		change({ coordinates, canvas, image }) {
			this.canvas = canvas;
		},
		zoomHandler(zoomRatio) {
			if (zoomRatio < 1) {
				this.options.zoom = zoomRatio;
			}
		},
		reset() {
			this.$emit('update:crop-image-data', null);
			this.$emit('update:show-dialog', false);
		},
		apply() {
			this.$emit('crop-apply', this.canvas.toDataURL('image/jpeg', 0.9));
			this.$emit('update:show-dialog', false);
		},
	},
};
</script>

<style></style>
